import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
const typeformBlockEl = document.getElementById("js-typeform-block");
let navigationHistory = [];
let focusReturn;

$(typeformBlockEl).on("click", function (e) {
  e.stopPropagation();
});

$(".js-open-typeform").on("click", function (e) {
  e.stopPropagation();
  disableBodyScroll(typeformBlockEl);
  $(typeformBlockEl).addClass("is-visible").attr("aria-hidden", "false");

  navigationHistory = [];
  showTypeformStep(0);
  focusReturn = this;
});

// function closeTypeForm() {
//   enableBodyScroll(typeformBlockEl);
//   $(typeformBlockEl).removeClass("is-visible").attr("aria-hidden", "true");
//   $(focusReturn).focus();
//   focusReturn = null;
// }

// $(".js-close-typeform, body").on("click", function (e) {
//   e.stopPropagation();
//   closeTypeForm();
// });

$(".js-back-typeform").on("click", function () {
  if (navigationHistory.length > 1) {
    navigationHistory.pop();
    showTypeformStep(navigationHistory.pop(), true, true);
  } else {
    showTypeformStep(0, false, true);
  }
});

$(typeformBlockEl)
  .find(".choices-ppa-toutm > div")

$(typeformBlockEl)
  .find(".choices-ppa-toutm > div")
  .on("mouseout", function () {
    $(this).find('.choice-hover').removeClass('d-flex').addClass('d-none');
  })
  .on("mouseover", function () {
    $(this).find('.choice-hover').removeClass('d-none').addClass('d-flex');
  })
  .on("click", function () {
    showTypeformStep($(this).find('button').attr("typeform-target"));
    return false;
  });

$(typeformBlockEl)
  .find("[typeform-target]")
  .on("click", function () {
    showTypeformStep($(this).attr("typeform-target"));
  });

$(typeformBlockEl)
  .find(".focus-trap:first-child")
  .on("focus", function () {
    $(typeformBlockEl).find(".hide").focus();
  });

$(typeformBlockEl)
  .find(".focus-trap:last-child")
  .on("focus", function () {
    focusQuestion();
  });

function focusQuestion() {
  setTimeout(() => {
    $(typeformBlockEl).find(".is-current").find("[tabindex]").first().focus();
  }, 200);
}

function showTypeformStep(step, flagHistory = true, isBack = false) {
  if (navigationHistory.length || isBack) {
    if (isBack) {
      $(typeformBlockEl)
        .find(".question.is-current")
        .addClass("is-moving is-below")
        .removeClass("is-current");

      $(typeformBlockEl).find(`[typeform-step="${step}"]`).addClass("is-above");
      setTimeout(() => {
        $(typeformBlockEl)
          .find(`[typeform-step="${step}"]`)
          .removeClass("is-above")
          .addClass("is-moving is-current");
      }, 100);
    } else {
      $(typeformBlockEl)
        .find(".question.is-current")
        .addClass("is-moving is-above")
        .removeClass("is-current");

      $(typeformBlockEl).find(`[typeform-step="${step}"]`).addClass("is-below");
      setTimeout(() => {
        $(typeformBlockEl)
          .find(`[typeform-step="${step}"]`)
          .removeClass("is-below")
          .addClass("is-moving is-current");
      }, 100);
    }
  } else {
    $(typeformBlockEl).find(".question.is-current").removeClass("is-current");
    $(typeformBlockEl).find(`[typeform-step="${step}"]`).addClass("is-current");
  }

  focusQuestion();

  setTimeout(() => {
    $(typeformBlockEl)
      .find(".question")
      .removeClass("is-moving is-above is-below");
  }, 400);

  if (flagHistory) {
    navigationHistory.push(step);
  }

  if (navigationHistory.length > 1) {
    $(".js-back-typeform").removeAttr("disabled");
  } else {
    $(".js-back-typeform").attr("disabled", "disabled");
  }
}

function highlightPPAChoice() {
  $('.choice-ppa-highlighted').removeClass('d-none').addClass('d-flex');
  $('.choice-toutm-highlighted').removeClass('d-flex').addClass('d-none');
}

function highlightTOUTMChoice() {
  $('.choice-toutm-highlighted').removeClass('d-none').addClass('d-flex');
  $('.choice-ppa-highlighted').removeClass('d-flex').addClass('d-none');
}

function selectPPAChoiceNextStep(step) {
  $('.ppa-choice').attr('typeform-target', step);
}

/* PCPME SIREN Form */
$("#typeform-siren-pcpme").on("submit", function (e) {
  e.preventDefault();

  const input = Number($("#typeform-siren").val());

  $(this).find(".ds-loader").addClass("is-visible");

  setTimeout(() => {
    if (input === 111111111) {
      showTypeformStep(2);
    } else if (input === 999999999) {
      $("#typeform-siren").removeClass("valide").addClass("erreur");
    } else {
      showTypeformStep(3);
    }

    $(this).find(".ds-loader").removeClass("is-visible");
  }, 1300);
});

$("#typeform-siren").on("input", function () {
  if ($(this).val().length === 9) {
    $(this).addClass("valide").removeClass("erreur");
    $("#typeform-siren-pcpme button").removeAttr("disabled");
  } else {
    $(this).removeClass("valide");
    $("#typeform-siren-pcpme button").attr("disabled", "disabled");
  }
});

$(".js-triptique-toggle-images").on("click", function () {
  $(".typeform-tp-container img").toggleClass("is-visible");
});


/**
 * @author Mansour BERREKIA
 * Module JS pour le module d'orientation
 * en fonction de numero d'adhérent
 * afin de rediriger l'utisateur vers son espace
 *
 * revealing module pattern JS
 */
 var adherentNumberFormModule = (function () {
  var moduleOrientation = [
    "CREATION",
    "CONNEXION",
    "AIDE_CONNEXION",
    "TOUTM",
    "CHOIX_TOUTM_PPA_CREATION",
    "CHOIX_TOUTM_PPA_CONNEXION",
    "CHOIX_TOUTM_PPA_AIDE_CONNEXION"
  ],
  apiUrl = "/api-particulier/parametrages/v1/redirections/orientation";

  /**
   * On input numero adherent
   * @param {*} params
   */
  function onInput() {
    $("#input-numero-adherent").on("input", function (event) {
      if ($(this).val().length === 8 || $(this).val().length === 9) {
        $(this).addClass("valide").removeClass("erreur");
        $("#typeform-numero-adherent button").removeAttr("disabled");
        return;
      }

      $(this).removeClass("valide");
      $("#typeform-numero-adherent button").attr("disabled", "disabled");
    });
  }

  /**
   * On submit numero adherent to BFF check orientation
   */
  function onSubmit() {
    $("#typeform-numero-adherent").on("submit", function (e) {
      e.preventDefault();
      const numeroAdherent = Number($("#input-numero-adherent").val());

      $(this).find(".ds-loader").addClass("is-visible");

      $.ajax({
        url: apiUrl,
        dataType: "json",
        method: 'GET',
        data: { 'identifiant': numeroAdherent},
      }).then(function (data) {
            hideLoader();
            if(!data || !moduleOrientation.includes(data.action)){
                showError('Numéro d’adhérent inconnu');
            }

            const choicePrefix = 'CHOIX_TOUTM_PPA_';
            if (data.action.startsWith(choicePrefix)) {
              const nextStep = data.action.substring(data.action.indexOf(choicePrefix) + choicePrefix.length);

              selectPPAChoiceNextStep(nextStep);

              showTypeformStep(`CHOIX_TOUTM_PPA`);

              if (String(numeroAdherent).length === 8) {
                highlightPPAChoice();
              }
              else {
                highlightTOUTMChoice();
              }
            }
            else {
              showTypeformStep(`${data.action}`);
            }
        })
        .fail(function (error) {

            hideLoader();

            if(error && error.responseJSON && error.responseJSON.status
              && error.responseJSON.status.messages.length && error.responseJSON.status.messages[0].code === "P002"){
              const msgError  =  error.responseJSON.status.messages[0];
              showError(msgError.libelle);
              return;
            }
            showError("Aïe ! Erreur Technique<br/>Nous sommes mobilisés pour résoudre le problème au plus vite.");
        });
    });

  }

  function showError(message) {
    $("#typeform-numero-adherent-error").html(message);
    $("#input-numero-adherent").removeClass("valide").addClass("erreur");
  }

  function hideLoader() {
    $("#loader").removeClass("is-visible");
  }
  // output a value based on the current configuration
  function init() {
    onInput();
    onSubmit();
  }

  return {
    init: init(),
  };
})();

adherentNumberFormModule.init;
