$('.menu-navbar__mobile-toggle').on('click', function () {
    $(this).children().toggleClass('is-on');
    $(this).siblings('.menu-navbar__container').toggleClass('is-visible');
});

$('#env-switch__button').on('click', function () {
    var elButton = $(this);
    var elList = $('#env-switch__list');

    if (elList.attr('hidden')) {
        hideSwitch(elButton, elList);
    }
    else {
        showSwitch(elButton, elList);
    }
});

function hideSwitch(elButton, elList) {
    elList.removeAttr('hidden');
    elButton.attr('aria-expanded', 'true');
}

function showSwitch(elButton, elList) {
    elList.attr('hidden', 'true');
    elButton.attr('aria-expanded', 'false');
}

/**
 * toggle mobile menu
 */
$('.popover__title').on('click', function () {
    $(".mobile__menu").toggle();
});